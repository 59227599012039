import React from 'react'

function Home (){
    return(
        <div id="home" name="home" className=" bg-hero-pattern h-screen bg-no-repeat bg-cover md:bg-[center_bottom_-14rem] bg-center bg-fixed">

            
        </div>
    )
}

export default Home;